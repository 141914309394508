import { Button, cn } from "@nextui-org/react";
import { useCallback, useState } from "react";
import { LuArrowRight } from "react-icons/lu";
import { Input } from "@nextui-org/input";
import { HiSparkles } from "react-icons/hi2";
import posthog from "posthog-js";
import { useNavigate } from "@remix-run/react";
import ReactGA from "react-ga4";
import { track } from "~/utils/track";

export default function Search({ placeholder }: { placeholder?: string }) {
  const navigate = useNavigate();

  const [query, setQuery] = useState("");

  const search = useCallback(
    (q: string) => {
      const trackMeta = async () => {
        await import("react-facebook-pixel")
          .then((x) => x.default)
          .then((ReactPixel) => {
            ReactPixel.track("Search", {
              search_string: q,
              currency: "INR",
              value: 100,
            });
          });
      };

      const trackGTM = async () => {
        ReactGA.event("conversion", {
          send_to: "AW-11437691875/jZ8yCPbvxcsZEOOn9c0q",
          value: 1.0,
          currency: "INR",
        });
      };

      trackMeta();
      trackGTM();

      posthog.capture("custom_search", {
        query: q,
      });
      track("custom_search", {
        query: q,
      });

      navigate(`/search?q=${encodeURIComponent(q)}`);
    },
    [navigate]
  );

  return (
    <Input
      className="focus:placeholder:opacity-0 drop-shadow-md"
      aria-label="Search"
      radius="full"
      size="lg"
      color="primary"
      variant="faded"
      placeholder={placeholder}
      value={query}
      onValueChange={setQuery}
      isClearable={false}
      onKeyDown={(e) => {
        if (e.key === "Enter" && query !== "") {
          search(query);
        }
      }}
      startContent={
        <HiSparkles
          className={cn(
            "w-5 h-5 -ml-2 text-primary",
            query.length === 0 && "text-default-400"
          )}
        />
      }
      endContent={
        <Button
          isIconOnly
          className="rounded-full -mr-3"
          color="primary"
          size="sm"
          onPress={() => search(query)}
          isDisabled={query === ""}
        >
          <LuArrowRight className="w-4 h-4" />
        </Button>
      }
    />
  );
}
